import request from '@/common/request';
import { IResponse } from '@/types/common';

export type IUnsubscribeAutoRenewalReq = {
  policy_id: string;
};

export type IUnsubscribeAutoRenewalRes = IResponse<{}>;

export const unsubscribeAutoRenewal = (
  params: IUnsubscribeAutoRenewalReq,
): Promise<IUnsubscribeAutoRenewalRes> => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api/h5/standalone/unsubscribe_auto_renewal',
      method: 'post',
      data: params,
    })
      .then((res) => {
        if (res?.code == 0 && res?.data) return resolve(res);
        reject(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
