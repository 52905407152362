import request from '@/common/request';
import { IResponse } from '@/types/common';

export type IsPaymentChannelActivatedReq = {
  product_id: string;
};

export type IsPaymentChannelActivatedRes = IResponse<{
  channel_id: string;
  is_activated: boolean;
  tc_json_str: string;
}>;

export const isPaymentChannelActivated = (
  params: IsPaymentChannelActivatedReq,
): Promise<IsPaymentChannelActivatedRes> => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api/h5/standalone/is_payment_channel_activated',
      method: 'post',
      data: params,
    })
      .then((res) => {
        if (res?.code == 0 && res?.data) return resolve(res);
        reject(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
