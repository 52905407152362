// import bridgeTrack, { AutoParamsOpts, CommonInfoData } from 'bridgetrack';
// import { getLocalItem, removeItem } from '@/module/storage';
// import { LAST_PAGE_TIME } from './constant';
import idapSDK from './idap';
import { TrackConfig } from '@finance/idap/es/lib/types';
import { getUrlClaimId, getUrlPolicyId, getUrlProductId } from '@/module/url';
/**
 * 参考文档
 * https://confluence.shopee.io/pages/viewpage.action?pageId=141157784#bridgetrack%E6%96%87%E6%A1%A3-%E6%8A%93%E5%8C%85%E9%AA%8C%E8%AF%81
 */
// interface ITrack {
//   config: Function;
//   autoExpo: Function;
//   autoParams: Function;
//   click: Function;
//   view: Function;
//   impress: Function;
//   clearImpr: Function;
//   page_type: string;
//   defaultData: any;
// }
// const nop = () => {};

// async function getLastPageTime() {
//   const time = await getLocalItem(LAST_PAGE_TIME, 0);
//   removeItem(LAST_PAGE_TIME, 'local');
//   return time;
// }
// const transformCommonInfo = (data: Partial<CommonInfoData> | any = {}) => {
//   data.data = Object.assign({}, bridge.defaultData, data.data);

//   if (!data.page_type) {
//     // 赋值设置的page_type
//     data.page_type = bridge.page_type || '';
//   }

//   if (!data.data!.from_source) {
//     // 赋值设置的from_source
//     data.data!.from_source = getUrlParamByKey('from');
//   }
//   if (process.env.REACT_APP_ENV == 'dev' && !data.page_type) {
//     // eslint-disable-next-line no-alert
//     alert('trackClick page need init by page_type');
//   }
//   return data;
// };
/**
 * 一般页面的入口设置好一次
 * trackConfig 或者 trackAutoExpo 设置page_type，后续的上报都不需要设置 page_type了
 * 也可以设置默认的数据全局的数据字段，比如 my motor : {
  type: 'motor',
  partner: 'allianz',
}
则后续所有的调用，都会携带 type,partner
 * @param page_type
 */
export function trackConfig(page_type: string, data: Record<string, any> = {}, isAuto = false) {
  // bridge.page_type = page_type || '';
  if (data && !data.product_id) {
    const pid = getUrlProductId();
    if (pid) {
      data.product_id = pid;
    }
  }
  if (data && !data.policy_id) {
    const pid = getUrlPolicyId();
    if (pid) {
      data.policy_id = pid;
    }
  }
  if (data && !data.claim_id) {
    const cid = getUrlClaimId();
    if (cid) {
      data.claim_id = cid;
    }
  }
  idapSDK.trackConfig(page_type, isAuto, data);
  // return bridgeTrack.config({ bridge: { call }, debug: process.env.REACT_APP_ENV !== 'live' });
}
/**
 * 一般页面的入口设置好一次
 * trackConfig 或者 trackAutoExpo 设置page_type，后续的上报都不需要设置 page_type了
 * 也可以设置默认的数据全局的数据字段，比如 my motor : {
  type: 'motor',
  partner: 'allianz',
}
则后续所有的调用，都会携带 type,partner
 * @param page_type
 */
export function trackAutoExpo(page_type: string, data?: Record<string, any>) {
  return trackConfig(page_type, data, true);
}
// export function trackAutoParams(
//   params: Partial<CommonInfoData> | any,
//   opts: Partial<AutoParamsOpts>,
// ) {
//   return bridgeTrack.autoParams(transformCommonInfo(params), opts);
// }
/**
 * 上报，可以重新设置整个 对象（如：{page_type:xxx,data:{xxx}}） 也可以只设置data的对象，如{xxx}
 * @param data
 * @example
 * trackClick({page_type:xxxx,target_type:xxxx,data:{type:xxx}})
 * trackClick({data:{type:xxx}})
 */
export function trackClick(data: Partial<TrackConfig> | any) {
  if (data) {
    data.report_imme = true;
  }
  idapSDK.track('trackClick', data);
  // const result = transformCommonInfo(data);

  // if (process.env.REACT_APP_ENV == 'dev' && !result.target_type) {
  //   // eslint-disable-next-line no-alert
  //   alert('trackClick no target_type');
  // }
  // if (process.env.REACT_APP_ENV == 'dev' || process.env.REACT_APP_ENV == 'test') {
  //   console.log('>track>Click>', result);
  // }
  // bridgeTrack.trackClickEvent(result);
}
/**
 * 上报View，页面级别上报
 * @param data
 */
export async function trackView(data: Partial<TrackConfig> | any = {}) {
  idapSDK.track('trackView', data);
  // const result = transformCommonInfo(data);
  // if (process.env.REACT_APP_ENV == 'dev' || process.env.REACT_APP_ENV == 'test') {
  //   console.log('>track>View>', result);
  // }
  // const last_page_time = await getLastPageTime();
  // result.data.last_page_time = last_page_time;
  // if (performance) {
  //   result.data.origin_time =
  //     (performance.timeOrigin || performance.timing.navigationStart) - last_page_time;
  // }
  // // @ts-ignore
  // const htmlStart = window.htmlStartTime;
  // result.data.html_start_time = htmlStart;
  // result.data.html_load_time = htmlStart - last_page_time;
  // result.data.js_load_time = Date.now() - last_page_time;

  // bridgeTrack.addViewTrackV2(result);
}
/**
 * 上报曝光 impress类型
 * @param data
 */
export function trackImpress(data: Partial<TrackConfig> | any) {
  idapSDK.track('trackImpress', data);
  // const result = transformCommonInfo(data);
  // if (process.env.REACT_APP_ENV == 'dev' || process.env.REACT_APP_ENV == 'test') {
  //   console.log('>track>Impress>', result);
  // }
  // bridgeTrack.addImpressionTrack(result);
}
export function trackCommon(data: Partial<TrackConfig> | any) {
  idapSDK.track('trackCommon', data);
}
export function trackAction(data: Partial<TrackConfig> | any) {
  idapSDK.track('trackCommon', { ...data, operation: 'action' });
}

export function trackReport() {
  idapSDK.track('trackReport');
}
