import { formatMsg } from '@/locales';

export function fullMonthToInternal(key: string | number) {
  return formatMsg(
    [
      '',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ][+key],
  );
}
export function monthToInternal(key: string | number) {
  return formatMsg(
    ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][+key],
  );
}
let monthToNumberArray: Record<string, number>;
export function monthToNumber(key: string) {
  if (!monthToNumberArray) {
    monthToNumberArray = {};
    for (let i = 1; i <= 12; i++) {
      monthToNumberArray[monthToInternal(i)] = i;
    }
  }

  return monthToNumberArray[key];
}
export function weekToInternal(key: string | number) {
  return formatMsg(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fir', 'Sat'][+key]);
}

export const PolicyStatusMap = {
  1: 'To Pay',
  2: 'Not Yet Issued',
  3: 'Active',
  4: 'To Activate',
  5: 'Expired',
  6: 'Cancelled',
  7: 'Not Yet Active',
} as const;

export type PolicyStatusType = keyof typeof PolicyStatusMap;

export const NetworkTipTextMap: Record<'title' | 'content' | 'retry', Record<string, string>> = {
  title: {
    id: 'Kesalahan Jaringan',
    my: 'Sistem sibuk',
    ph: 'Network Error',
    th: 'เครือข่ายขัดข้อง',
    vn: 'Lỗi kết nối',
  },
  content: {
    id: 'Kesalahan Jaringan, Silakan Coba Lagi Nanti.',
    my: 'Sistem sibuk, sila cuba sebentar lagi.',
    ph: 'Network Error. Please Try Again Later.',
    th: 'เครือข่ายขัดข้อง กรุณาลองอีกครั้ง',
    vn: 'Lỗi kết nối, Vui lòng thử lại.',
  },
  retry: {
    id: 'Coba Lagi',
    my: 'Cuba lagi',
    ph: 'Try Again',
    th: 'ลองอีกครั้ง',
    vn: 'Thử lại',
  },
};
export const LAST_PAGE_TIME = 'last_page_time';

export const TH_PA = ['1762063430996471867', '1762057853155160113', '1762030202810015801'];
