/**
 * xss 处理
 * 后续有需要，可以参考 ：https://github.com/YahooArchive/xss-filters
 * @param str 需要做 xss 的字符串
 * @returns
 */
export default function xss(str: string = '') {
  try {
    return str.replace(
      /<(script|iframe)|(([\s([]|^)alert\s*\()|(([\s([]|^)(window|location|document|localStorage)\s*[.[()])|javascript:/gi,
      '',
    );
  } catch (e) {
    return str;
  }
}
const whiteDomain = [
  '.shopee.co.id',
  '.shopee.com.my',
  '.shopee.vn',
  '.shopee.ph',
  '.seainsurebroker.com.ph',
  '.shopee.co.th',
  '.seainsurebroker.co.th',
  '.shopee.sg',
  '.shopee.com.br',
];
export function isAdminOrigin(origin: string) {
  if (!origin) return false;
  return whiteDomain.some((key: string) => {
    if (origin.indexOf(key) >= 0) {
      return true;
    }
  });
}
