import React, { FC } from 'react';
import { NativeProps, withNativeProps } from './helper';

type Props = {
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children?: React.ReactNode;
} & NativeProps;

export const SwiperItem: FC<Props> = (props) => {
  return withNativeProps(
    props,
    <div className="adm-swiper-item" onClick={props.onClick} onScroll={props.onScroll}>
      {props.children}
    </div>,
  );
};
