import { formatMsg } from '@/locales';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import PopupMessage, { IPopupMessage } from './PopupMessageElement';

const popupList: Root[] = [];

export const hideH5Popup = (unmount?: boolean) => {
  const popupContainer = document.querySelector('#__ins_popup_parent__');
  if (popupContainer) {
    if (unmount) {
      // unmount
      popupList.forEach((popup) => popup.unmount()); // 定时器等需要被销毁
      popupList.splice(0);
    }
    // unlock scroll
    document.body.style.overflow = 'scroll';
    document.body.removeChild(popupContainer);
  }
};

export type IH5PopupProps = {
  unmountOnMaskClose?: boolean;
  maskClosable?: boolean;
  keepPopupOnOk?: boolean;
} & IPopupMessage;

export const showH5Popup = (options: IH5PopupProps = {}) => {
  const onOk = () => {
    if (options.onOk) options.onOk();
    if (options.keepPopupOnOk) return;
    hideH5Popup();
  };
  const onCancel = () => {
    if (options.onCancel) options.onCancel();
    hideH5Popup();
  };

  const { maskClosable, unmountOnMaskClose, ...opts } = options;
  const injectOptions: IPopupMessage = {
    okText: formatMsg('common.component.popup.ok'),
    cancelText: formatMsg('common.component.popup.cancel'),
    okBtn: true,
    ...opts,
    onOk,
    onCancel,
  };

  if (maskClosable) {
    injectOptions.onContainerClick = () => {
      hideH5Popup(unmountOnMaskClose);
    };
    injectOptions.onWrapperClick = (e) => {
      e.stopPropagation();
    };
  }

  const currentPopupContainer = document.querySelector('#__ins_popup_parent__');
  if (currentPopupContainer) {
    return;
  }
  // lock scroll
  document.body.style.overflow = 'hidden';
  const popupContainer = document.createElement('div');
  popupContainer.id = '__ins_popup_parent__';
  document.body.appendChild(popupContainer);
  const root = createRoot(popupContainer);
  root.render(<PopupMessage {...injectOptions}></PopupMessage>);
  popupList.push(root);
};

window.showH5Popup = showH5Popup;
