/**
 * 订阅发布者模式，实现粘性监听，即发布消息在前，监听消息在后也能响应
 * @example
 * 例子1：
 * listen("__product_msg",()=>{})
 * trigger("__product_msg",data);// 这里trigger之后， listen 就能回调
 *
 * 例子2：
 * trigger("__product_info",data);// 这里trigger了消息，但是还没有listern
 * listen("__product_info",()=>{});// 这里监听，则会回调上面发布的data数据，因此不需要关注 trigger 和  listen 的顺序
 *
 * @author wenlin.luo
 */

// 存储的事件分发器
const handlers: Record<string, { handler: Function; isOnce: boolean }[]> = {};
// 触发时间的缓存，以便emit 之后再监听的
const preEmitQueue: Record<string, any[]> = {};
interface Ioptions {
  isOnce?: boolean;
  delay?: false | number;
}
/**
 * 事件监听函数
 * @param name 监听事件的名称
 * @param fn 事件触发的回调函数
 * @param options {
 * isOnce 是否只监听一次,
 * delay 如果有粘性发布，是否立即执行 默认是延时 40ms 回调，  false 即不延时 立即执行，100 是延时 100ms执行
 * }
 * listen(xxxx,xxx,{delay:false})
 * listen(xxxx,xxx,{delay:100})
 */
export function listen(name: string, fn: Function, options: Ioptions = {}) {
  const isOnce = options.isOnce || false;

  // 分发粘性事件
  const c = preEmitQueue;
  let triggered = false;
  if (c && c[name]) {
    const execute = () => {
      c[name].forEach((d) => {
        fn(...d);
      });
    };
    triggered = true;
    if (options.delay === false) {
      execute();
    } else {
      setTimeout(() => {
        execute();
      }, options.delay || 40);
    }
  }
  if (!handlers[name]) handlers[name] = [];
  if (!triggered || !isOnce) {
    handlers[name].push({
      handler: fn, // 处理函数
      isOnce, // 是否只执行一次
    });
  }
}
/**
 * 只监听一次的监听函数
 * @param name 事件监听的名称
 * @param fn 事件触发的回调函数
 */
export function once(name: string, fn: Function) {
  listen(name, fn, { isOnce: true });
}
/**
 * 发送消息，但是不做粘性触发
 * 即发送的时候，如果还没有监听，在该发送就会丢失
 * @param name 事件监听的名称
 * @param params 触发的参数
 */
export function trigger_ns(name: string, ...params: any[]) {
  if (process.env.REACT_APP_ENV == 'dev' || process.env.REACT_APP_ENV == 'test') {
    if (!name || !name.startsWith('__')) {
      // name 要求两个下划线开头，是为了规避根据 name 能精准搜索到代码，否则查找消息 trigger 的位置很痛苦
      console.error(
        `%c事件监听的名称[${name}]不符合规定，请使用双下划线开头，如:__product_info`,
        'color:red;font-size:30px;',
      );
    }
  }

  if (!handlers[name] || handlers[name].length == 0) return;
  const tempHandlers: { handler: Function; isOnce: boolean }[] = [];
  handlers[name].forEach((item) => {
    item.handler.apply(null, params);
    if (!item.isOnce) {
      // 只执行一次的就废弃掉
      tempHandlers.push(item);
    }
  });
  handlers[name] = tempHandlers;
}
/**
 * 这个是粘性事件，即发送完消息之后，仍然会储存消息，以便之后触发的监听能够响应
 * @param name 事件监听的名称
 * @param params 触发的参数
 */
export function trigger(name: string, ...params: any[]) {
  trigger_ns(name, ...params);

  if (!preEmitQueue[name]) {
    preEmitQueue[name] = [];
  }

  preEmitQueue[name].push(params);
}
/**
 * 删除某个监听，如果有参数 fn 则删除指定监听的函数，否则该名称的全部监听
 * @param name 事件监听的名称
 * @param fn 监听回调的函数
 */
export function remove(name: string, fn?: Function) {
  if (!handlers[name] || handlers[name].length == 0) return;

  if (!fn) {
    handlers[name] = [];
  } else {
    const handler = [];
    for (let i = 0, len = handlers[name].length; i < len; i++) {
      if (handlers[name][i].handler !== fn) {
        handler.push(handlers[name][i]);
      }
    }
    handlers[name] = handler;
  }
}
