const { REACT_APP_ENV } = process.env;
const subConfig =
  REACT_APP_ENV === 'live'
    ? {
        appId: 'h5',
        secretKey: '815781561e80fe2eb78ff84453057564',
        publicKey: '',
      }
    : {
        appId: 'h5',
        secretKey: 'cb8ea566aa6a362507e0d6655b8de70a',
        publicKey: '',
      };

const envConfig = (() => {
  // mock, dev, test1
  if (['mock', 'dev', 'test1'].indexOf(REACT_APP_ENV) >= 0) {
    return {};
  }

  // live
  if (REACT_APP_ENV === 'live') {
    return {};
  }

  // test, uat
  return {};
})();

const config = {
  platForm: 1,
  version: 1,
  region: 'sg',
  insuranceVersion: 'insurance_h5_v1.0.0.100',
  // appVersion: bridge.checkAppVersion(),
  // rnVersion: bridge.checkRnVersion(),
  ...subConfig,
  ...envConfig,
};

export default config;
