import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
  createRef,
  useRef,
} from 'react';
import { createRoot } from 'react-dom/client';
import useMemoizedFn from '@/hooks/useMemoizeFn';

export interface PortalHandler {
  close: () => void;
}

export type IPortalContent = { visible: boolean; onClose?: () => void };

export type CPortalContent = (props: IPortalContent) => JSX.Element | null;

export interface OpenPortalOptions {
  simpleMode?: boolean;
  animationDuration?: number;
  target?: string;
}

// 指令式打开弹出类组件
export function openPortal(
  PortalContent: CPortalContent,
  params?: Omit<IPortalContent, 'visible'>,
  options?: OpenPortalOptions,
) {
  const { simpleMode, animationDuration, target } = options || {};
  const targetElem = (target && document.querySelector(target)) || document.body;
  const container = document.createElement('div');
  targetElem.appendChild(container);

  const root = createRoot(container);

  function unmount() {
    root.unmount();
    targetElem.removeChild(container);
  }

  const Wrapper = forwardRef<PortalHandler>((props, compRef) => {
    const [visible, setVisible] = useState(false);
    const mounted = useRef(false);

    const { onClose, ...rawProps } = params || {};

    const onSheetClose = useMemoizedFn(() => {
      setVisible(false);
      onClose?.();
    });

    useEffect(() => {
      if (!mounted.current) return;
      // 某些组件有动画
      if (!visible) setTimeout(() => unmount(), animationDuration || 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
      if (mounted.current) return;
      // 某些组件有动画
      setVisible(true);
      mounted.current = true;
    }, []);

    useImperativeHandle(compRef, () => {
      return {
        close: onSheetClose,
      };
    });

    if (simpleMode) {
      return visible ? (
        <PortalContent {...rawProps} visible={visible} onClose={onSheetClose} />
      ) : null;
    }

    return <PortalContent {...rawProps} visible={visible} onClose={onSheetClose} />;
  });

  const wrapperRef = createRef<PortalHandler>();
  root.render(<Wrapper ref={wrapperRef} />);

  const instance = {
    close() {
      wrapperRef.current?.close();
    },
  };
  return instance;
}

export function createOpenPortalFn<T>(
  Comp: (props: T) => JSX.Element | null,
  options?: OpenPortalOptions,
) {
  return function open(params?: Omit<T, 'visible'>) {
    return openPortal(Comp as any, params, options);
  };
}
