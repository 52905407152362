import { getNow } from '../date';
import { safeDecodeURI } from '../util';

/**
 * 获取Cookie
 * @function get
 * @param {String} name key值
 * @returns
 */
export function getCookie(name: string) {
  const reg = new RegExp(`(^| )${name}(?:=([^;]*))?(;|$)`);
  const val = document.cookie.match(reg);
  if (!val || !val[2]) {
    return '';
  }
  return safeDecodeURI(val[2]);
}
type CookieName = 's_token' | 'userid';
/**
 *
 * @param name
 * @param value
 * @param options
 * @description {
 * expires : 单位小时 ,默认 24小时
 * }
 */
export function setCookie(
  name: CookieName,
  value: string | number,
  options: { expires?: number } = {},
) {
  const domain = location.host.replace(/[^.]*/, '');

  document.cookie = `${name}=${value};path=/;expires=${new Date(
    getNow() + (options.expires || 24) * 60 * 60 * 1000,
  ).toUTCString()};domain=${domain}`;
}

const emptyCookie = ['', '0', 'null', 'undefined'];

export function isEmptyCookie(name: string) {
  return emptyCookie.includes(getCookie(name).toLowerCase());
}

export function getShopeeUserId() {
  return getCookie('userid') || getCookie('shopee_user_id') || '';
}
