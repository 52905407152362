import { forceHttps } from '@/module/url';
import { call } from './bridge';

export enum ContactType {
  phone_number = 'phone_number',
  whatsapp = 'whatsapp',
  email = 'email',
  line = 'line',
  web = 'web',
  zalo = 'zalo',
}
export function openExternalLink(type: ContactType, url: string) {
  if (!url) return;

  let result = '';
  switch (type) {
    case ContactType.phone_number: {
      result = `tel:${url}`;
      break;
    }
    case ContactType.whatsapp: {
      result = `https://wa.me/${url.replace(/-|\s/g, '')}`;
      break;
    }
    case ContactType.email: {
      result = `mailto:${url}`;
      break;
    }
    case ContactType.zalo: {
      /* We will create link Zalo personal account in the syntax https://zalo.me/84 your phone number.
      In the section enter the link for your personal Zalo account remove 0 when entering your personal phone number.
      因为zalo不止有电话号码形式，还有字符形式，因此跳转会有一些问题，不支持跳转了
      */
      // result = `https://zalo.me/84${url.replace(/-|\s/g, '')}`;
      break;
    }
    case ContactType.web: {
      result = forceHttps(url);
      break;
    }
    default:
      return;
  }
  console.log('openExternalLink', result);
  if (!result) return;
  call('openExternalLink', { url: result }).then((err) => {
    console.log('openExternalLink result', err);
  });
}
