import React from 'react';
import { createRoot } from 'react-dom/client';
import { LoadingContainer } from './loadingElements';

export interface ILoadingOptions {
  choke?: boolean;
}

export const showLoading = (options?: ILoadingOptions) => {
  const currentLoadingContainer = document.querySelector('#__ins_loading_parent__');
  if (currentLoadingContainer) {
    return;
  }
  const loadingContainer = document.createElement('div');
  loadingContainer.id = '__ins_loading_parent__';
  loadingContainer.className = `ct-loading-container ${
    options?.choke ? 'ct-loading-container-choke' : ''
  }`;
  document.body.appendChild(loadingContainer);
  const root = createRoot(loadingContainer);

  if (options?.choke) {
    document.body.style.overflowY = 'hidden';
  }
  const defaultOptions = {
    choke: false,
    ...(options || {}),
  };
  root.render(<LoadingContainer {...defaultOptions}></LoadingContainer>);
};

export const hideLoading = (keepOverflow?: boolean) => {
  const loadingContainer = document.querySelector('#__ins_loading_parent__');
  if (loadingContainer) {
    document.body.removeChild(loadingContainer);
  }

  if (keepOverflow) return;
  document.body.style.overflowY = 'scroll';
};
