export const TAB_BAR_HEIGHT = '__tab_bar_height';
export const TAB_BAR_GRADUAL_HEIGHT = '__tab_bar_gradual_height';
export const TAB_BAR_LIST = '__tab_bar_list';
export const TAB_BAR_SCROLL = '__tab_bar_scroll';
export const KEYBORD_CHANGE = '__keyborad_change';
export const SCROLL_TO_CONTENT = '__sctoll_content';
export const HOMEPAGE_BUBBLE_UPDATE = '__homepage_bubble_update';
export const HOMEPAGE_BUBBLE_RESET = '__homepage_bubble_reset';
export const HOMEPAGE_CHANGE_TAB = '__homepage_change_tab';
export const HOMEPAGE_TAB_CHANGEG = '__homepage_tab_changed';
