import request from '@/common/request';

class TransifyUsageReport {
  private queue: string[] = [];
  private maxCount = 30;
  private timeOutId: any;
  private timeInternal = 5 * 1000;
  constructor() {
    this.init();
  }
  private init() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.report();
        // navigator.sendBeacon('/log', analyticsData);
      }
    });
  }
  private request(transifyKeys: string[]) {
    // if (window.navigator && typeof navigator.sendBeacon === 'function') {
    // }

    request({
      url: 'https://pfc.insurance.test.shopee.io/api/nologin/transify_usage/report',
      method: 'POST',
      data: {
        transify_keys: transifyKeys.map((v) => ({
          key: v,
          region: process.env.REGION.toUpperCase(),
          path_name: location.pathname,
        })),
      },
    })
      .then((res) => {
        if (res.code !== 0) {
          throw new Error(res.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.queue.concat(transifyKeys);
      });
  }

  private report() {
    if (this.queue.length >= this.maxCount) {
      this.request(this.queue.splice(0, this.queue.length));
    }
    clearTimeout(this.timeOutId);
    setTimeout(() => {
      if (this.queue.length === 0) return;
      this.request(this.queue.splice(0, this.queue.length));
    }, this.timeInternal);
  }

  addTransifyKey(key: string) {
    if (!this.queue.includes(key)) {
      this.queue.push(key);
    }

    this.report();
  }
}

// eslint-disable-next-line import/no-mutable-exports
let transifyUsageReport;

if (!transifyUsageReport) {
  if (process.env.REACT_APP_ENV == 'test' || process.env.REACT_APP_ENV == 'uat') {
    transifyUsageReport = new TransifyUsageReport();
  }
}

export default transifyUsageReport as TransifyUsageReport;
