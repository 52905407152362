// 如果是业务组件，service中存放该组件的请求
import request, { IRequestParam } from '@/common/request';
import { setLocalItem, getLocalItem } from '@/module/storage';
import {
  ERecommendInfoType,
  EReferIdType,
  EResourceCode,
  IResponse,
  Product,
  ProductShelvesItem,
  ResourceConfigDetail,
  VoucherDetail,
  ReferralCardInfo,
} from '@/types/common';
import { isNumber } from './util';
import { isH5Url } from '@/module/url';

const country = process.env.REGION;
const currentEnv = process.env.REACT_APP_ENV;

// function report(
//   key: string,
//   options: { code: number; status: boolean; duration?: number; error?: any; http_status?: number },
// ) {
//   api(key, {
//     type: 'fetch',
//     ...options,
//   });
// }
/**
 *
 * @param key cnd 的路径 key
 * @param options retryCount 默认是1 ，重试一次，country是否固定某个国家，如果固定，则总是请求这个国家的链接
 * @returns
 */
export const getCDNConfig = function (
  key: string,
  options: {
    retryCount?: number;
    country?: string;
    noCache?: boolean;
    reportKey?: string;
    cacheKey?: string | boolean; // 为true或非空字符串，说明需要缓存，缓存storage的key值优先依次取cacheKey和reportKey。否则不缓存。
  } = {},
): Promise<any> {
  return new Promise((resolve, reject) => {
    // const reportKey = `api_${options.reportKey || key}`;
    // eslint-disable-next-line no-nested-ternary
    const cacheKey = options.cacheKey
      ? typeof options.cacheKey == 'string'
        ? options.cacheKey
        : options.reportKey
      : '';
    const retryCount = isNumber(options.retryCount) ? options.retryCount : 1;
    if (process.env.REACT_APP_ENV != 'live') {
      options.noCache = true;
    }
    // custom(reportKey, {
    //   loc: 'api',
    //   start_time: Date.now(),
    //   msg: key,
    // });
    const exe = async (count = 0, err?: any) => {
      if (count > retryCount!) {
        // !window.isJumpingLock &&
        //   report(reportKey, {
        //     error: err,
        //     status: false,
        //     code: -1,
        //   });
        if (cacheKey) {
          const res = await getLocalItem(cacheKey);
          if (res) {
            return resolve(res);
          }
          // report(reportKey, { error: err, status: false, code: -2 });
        }
        return reject(err);
      }
      // const startTime = Date.now();
      const url = key.startsWith('http')
        ? key
        : `https://cdn.insurance.shopee.com/insurance-cdn/json-config/${
            options.country || country
          }/${currentEnv === 'mock' || currentEnv === 'dev' ? 'test' : currentEnv}/${key}.json`;
      fetch(
        `${url}?tag=${process.env.RELEASE_TAG}${
          count > 0 || options.noCache ? `&t=${Date.now()}` : ''
        }`,
      )
        .then(async (res) => {
          // const diff = Date.now() - startTime;
          // const params = {
          //   http_status: res.status,
          //   duration: diff,
          // };
          if (res.ok) {
            const result = await res.json();
            if (!result || Object.keys(result).length == 0) {
              // report(reportKey, {
              //   ...params,
              //   code: 2,
              //   status: false,
              // });
              return reject(res);
            }
            const lang = window.INS_LOCALE.toLowerCase() || 'en';
            // report(reportKey, {
            //   ...params,
            //   code: 0,
            //   status: true,
            // });
            const rst = {
              ...result,
              ...result[lang],
            };
            if (cacheKey) {
              setLocalItem(cacheKey, rst);
            }
            return resolve(rst);
          }
          // report(reportKey, {
          //   ...params,
          //   code: 1,
          //   status: false,
          // });
          exe(++count, res);
        })
        .catch((error) => {
          // custom(reportKey, {
          //   code: -2,
          //   error,
          //   duration: Date.now() - startTime,
          // });
          exe(++count, error);
        });
    };
    exe(0);
  });
};

export function getPfcCDNConfig<T>(
  key: string,
  options: {
    country?: string;
    noCache?: boolean;
  } = {},
): Promise<T> {
  const realKey = key.startsWith('http')
    ? key
    : `https://cdn.insurance.shopee.com/pfc/${options.country || country}/${
        currentEnv === 'mock' || currentEnv === 'dev' ? 'test' : currentEnv
      }/${window.INS_LOCALE}/${key}.json`;
  return getCDNConfig(realKey, options);
}

/**
 *
 * @param key PFC 系统配置的 Fragment Name
 * @param options
 * @returns
 */
export function getCDNConfigPfc(
  key: string,
  options: {
    retryCount?: number;
    country?: string;
    noCache?: boolean;
    cacheKey?: string; // 缓存storage的key值优先依次取cacheKey和reportKey。否则不缓存。
  } = {},
): Promise<any> {
  return new Promise((resolve, reject) => {
    const cacheKey = options.cacheKey;
    const retryCount = isNumber(options.retryCount) ? +options.retryCount! : 1;
    if (process.env.REACT_APP_ENV != 'live') {
      options.noCache = true;
    }
    const exe = async (count = 0, err?: any) => {
      if (count > retryCount) {
        if (cacheKey) {
          const res = await getLocalItem(cacheKey);
          if (res) {
            return resolve(res);
          }
        }
        return reject(err);
      }
      const url = isH5Url(key)
        ? key
        : `https://cdn.insurance.shopee.com/pfc/${options.country || country}/${
            currentEnv === 'mock' || currentEnv === 'dev' ? 'test' : currentEnv
          }/${window.INS_LOCALE}/${key}.json`;
      fetch(
        `${url}?tag=${process.env.RELEASE_TAG}${
          count > 0 || options.noCache ? `&t=${Date.now()}` : ''
        }`,
      )
        .then(async (res) => {
          if (res.ok) {
            const result = await res.json();
            if (!result || Object.keys(result).length == 0) {
              return reject(res);
            }
            if (cacheKey) {
              setLocalItem(cacheKey, result);
            }
            return resolve(result);
          }
          exe(++count, res);
        })
        .catch((error) => {
          exe(++count, error);
        });
    };
    exe(0);
  });
}

export interface IProductCard {
  title?: string;
  product_id?: string;
  banner_pic?: string;
  pdp_link?: string;
}

export interface IQueryRecommendInfoRes {
  recommend_list: RecommendDetail[];
}

export interface RecommendDetail {
  display_type: ERecommendInfoType;
  protection_products: Partial<Product>[];
  product_cards: ResourceConfigDetail[];
  voucher_card: {
    unused_count: number;
    view_link: string;
    resource_configs?: ResourceConfigDetail[];
    voucher_details?: VoucherDetail[];
  };
  recommended_product_cards: ProductShelvesItem[];
  notice_bars: ResourceConfigDetail[];
  referral_card_info: ReferralCardInfo;
}

export function queryRecommendInfo(
  params: {
    // source?: EPageSource;
    resource_code: EResourceCode;
    id_type?: EReferIdType;
    refer_id?: string;
  },
  options?: Partial<IRequestParam>,
): Promise<IResponse<IQueryRecommendInfoRes>> {
  return new Promise((resolve, reject) => {
    request({
      method: 'post',
      url: '/api/h5/standalone/query_recommend_info',
      data: params,
      ...options,
    })
      .then((data) => {
        if (!data) throw reject(new Error('empty'));
        if (data.code != 0 || !data.data) {
          return reject(data.message);
        }
        resolve(data);
      })
      .catch((err) => {
        reject((err && err.message) || err);
      });
  });
}

export interface RecommendReqDetail {
  resource_code: EResourceCode;
  refer_id?: string;
  id_type?: EReferIdType;
}

export interface IBatchQueryRecommendInfoRes {
  res_list: {
    resource_code: EResourceCode;
    recommend_list: RecommendDetail[];
  }[];
}
export function batchQueryRecommendInfo(
  params: {
    // source?: EPageSource;
    req_list: RecommendReqDetail[];
  },
  options?: Partial<IRequestParam>,
): Promise<IResponse<IBatchQueryRecommendInfoRes>> {
  return new Promise((resolve, reject) => {
    request({
      method: 'post',
      url: '/api/h5/standalone/batch_query_recommend_info',
      data: params,
      ...options,
    })
      .then((data) => {
        if (!data) throw reject(new Error('empty'));
        if (data.code != 0 || !data.data) {
          return reject(data.message);
        }
        resolve(data);
      })
      .catch((err) => {
        reject((err && err.message) || err);
      });
  });
}
