// import ExceptionPlugin from '@mdap/sdk-plugin-exception';
import idapSDK from './idap';
import { IdapReportParams } from '@finance/idap/es/report';

// JS 异常信息监控插件
// const exceptionHandler = new ExceptionPlugin({
//   mode: 'interval',
//   interval: 3 * 1000,
// });

// export default exceptionHandler;

/**
 *
 * @param unique_key 搜索统计的唯一的 key
 * @param message 上报消息
 * customS("xxxx",1,2)
 */
export function customMsg(unique_key: string, ...message: any[]) {
  idapSDK.custom(unique_key, { message });
}
/**
 *
 * @param unique_key 搜索统计的唯一的key
 * @param params 上报的对象，如 {message:"xxxx"}
 */
export function custom(unique_key: string, params?: IdapReportParams | undefined) {
  idapSDK.custom(unique_key, params);
}
/**
 *
 * @param unique_key 搜索统计的唯一的key
 * @param params 上报的对象，如 {message:"xxxx"}
 */
export function customErr(unique_key: string, params?: IdapReportParams | undefined) {
  idapSDK.custom(unique_key, { level: 'error', ...params });
}
/**
 *
 * @param unique_key 搜索统计的唯一的key
 * @param params 上报的对象，如 {message:"xxxx"}
 */
export function customWarn(unique_key: string, params?: IdapReportParams | undefined) {
  idapSDK.custom(unique_key, { level: 'warn', ...params });
}

// export function api(api_name: string, params: IdapApi) {
//   idapSDK.api(api_name, params);
// }

type Tlevel = 'error' | 'debug' | 'fatal' | 'info' | 'warn' | undefined;

/**
 * 上报信息，和 mdap 参数一致
 * name: // 这个是显示的名字，也就是 mdap 查询的 key 可以根据这个 name 来分类查询数据
 * message?: string; // 这个是错误的信息，在 mdap 查询的错误信息
 * @param obj
 * @returns
 * @deprecated
 */
export const capture = function (obj: {
  name: string; // 这个是显示的名字，也就是 mdap 查询的 key
  message?: string; // 这个是错误的信息，在 mdap 查询的错误信息
  error?: Error; // 如果是抛出的 Error 则放这里，最终显示是 message + error 一起显示
  data?: {
    data_field?: Record<string, string> | undefined;
    level?: Tlevel; // 默认是 error
    extra?: Record<string, unknown> | undefined;
  };
}) {
  const dataField = (obj.data || {}).data_field;

  custom(obj.name, {
    ...dataField,
    msg: obj.message || '',
    error: obj.error,
  });
};
/**
 * 已经弃用，采用idap上报
 * 上报信息，与 capture 一样，简化参数
 * @param name // 这个是显示的名字，也就是 mdap 查询的 key
 * @param message // 这个是错误的信息，在 mdap 查询的错误信息
 * @param level // 'error' | 'debug' | 'fatal' | 'info' | 'warn'
 * @param data_field data_field
 * @deprecated
 */
export const captureSimple = function (
  name: string,
  message: string,
  level?: Tlevel,
  data_field?: Record<string, string> | undefined,
) {
  capture({
    name,
    message,
    data: {
      level,
      data_field,
    },
  });
};
/**
 * 已弃用 请使用 采用idap上报 函数 custom api 上报
 * @deprecated
 */
export const captureError = function (obj: {
  message: string;
  error?: Error;
  errorInfo?: {
    name?: string | undefined;
    data_field?: Record<string, string> | undefined;
    level?: 'error' | 'debug' | 'fatal' | 'info' | 'warn' | undefined;
    extra?: Record<string, unknown> | undefined;
  };
}) {
  capture({
    name: (obj.errorInfo || {}).name || obj.message,
    message: obj.message,
    error: obj.error,
    data: obj.errorInfo,
  });
};
