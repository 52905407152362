import request from '@/common/request';
import { IQueryRenewPolicyRes } from '@/types/renewConfirmation';

export async function queryRenewPolicy(params: {
  product_id: string;
  id_card_no?: string;
  certi_type?: string;
  account_member_id?: string;
}) {
  return request({
    method: 'post',
    url: '/api/h5/standalone/query_renew_policy',
    data: params,
    showLoading: true,
  }).then(async (res: IQueryRenewPolicyRes) => {
    if (!res?.data || res?.code !== 0) {
      throw new Error(res?.message);
    }
    return res?.data;
  });
}
