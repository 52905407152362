import React, { CSSProperties } from 'react';
import './index.scss';

interface ILoadingProps {
  size?: CSSProperties;
  cls?: string;
  choke?: boolean;
}

export const LoadingContainer = (props: ILoadingProps) => {
  const { cls, choke, size } = props;
  return (
    <div className={`${cls} ${choke ? 'ct-loading-bg-choke' : ''}`}>
      <div className="ct-load ct-load1" style={size} />
      <div className="ct-load ct-load2" style={size} />
      <div className="ct-load" style={size} />
    </div>
  );
};

const Loading = () => {
  return (
    <div className="ct-loading-bg">
      <LoadingContainer cls={'ct-load-container'} />
    </div>
  );
};

export default Loading;
