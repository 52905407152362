import React from 'react';
import classNames from 'classnames';
import { formatMsg } from '@/locales';
import { formatCurrencyV2 } from '@/common/biz';
import { IAutoRenewReceivableInfo } from '@/service/autoRenew';
import './index.scss';

export interface IAutoRenewDeduction {
  info?: IAutoRenewReceivableInfo;
}

export function getDeductMsg(info: IAutoRenewReceivableInfo) {
  if (info.is_failure) return formatMsg('autorenew.deduct.error');

  const deductInfo = {
    amount: formatCurrencyV2(info.premium),
    date: info.deduct_date,
  };

  const key = Object.keys(deductInfo)
    .filter((i) => (deductInfo as any)[i])
    .join('.');

  if (!key) return '';

  return formatMsg(`autorenew.deduct.${key}`, deductInfo).replace(
    /\[([^\]]+)\]/g,
    '<span>$1</span>',
  );
}

export default function AutoRenewDeduction(props: IAutoRenewDeduction) {
  const { info } = props;

  return info ? (
    <div
      className={classNames(
        'autorenew-deduction',
        info.is_failure ? 'autorenew-deduction_error' : 'autorenew-deduction_info',
      )}
      dangerouslySetInnerHTML={{
        __html: getDeductMsg(info),
      }}
    ></div>
  ) : null;
}
