import React, { AriaAttributes, useEffect, useLayoutEffect } from 'react';
import type {
  CSSProperties,
  ReactElement,
  FC,
  PropsWithChildren,
  Ref,
  ForwardRefRenderFunction,
} from 'react';
import classNames from 'classnames';
import { assignWith } from 'lodash-es';

type StageRender = () => StageRender | ReactElement | null;
type StageRenderRoot<P> = (props: PropsWithChildren<P>) => StageRender | ReactElement | null;
type StageRenderRootWithRef<P, R> = (
  props: PropsWithChildren<P>,
  ref: Ref<R>,
) => StageRender | ReactElement | null;

function processNext(next: StageRender | ReactElement | null) {
  if (typeof next === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return <Stage stage={next} />;
  }
  return next;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Stage<P>(props: { stage: StageRender }) {
  const next = props.stage();
  return processNext(next);
}

export function staged<P = {}>(stage: StageRenderRoot<P>): FC<P>;
// eslint-disable-next-line no-redeclare
export function staged<P = {}, R = any>(
  stage: StageRenderRootWithRef<P, R>,
): ForwardRefRenderFunction<R, P>;
// eslint-disable-next-line no-redeclare
export function staged<P = {}, R = any>(stage: StageRenderRootWithRef<P, R>) {
  return function Staged(props, ref) {
    const next = stage(props as any, ref);
    return processNext(next);
  } as FC<P>;
}

export function attachPropertiesToComponent<C, P extends Record<string, any>>(
  component: C,
  properties: P,
): C & P {
  const ret = component as any;
  for (const key in properties) {
    if (Object.prototype.hasOwnProperty.call(properties, key)) {
      ret[key] = properties[key];
    }
  }
  return ret;
}

export type NativeProps<S extends string = never> = {
  className?: string;
  style?: CSSProperties & Partial<Record<S, string>>;
  tabIndex?: number;
} & AriaAttributes;

export function withNativeProps<P extends NativeProps>(props: P, element: ReactElement) {
  const p = {
    ...element.props,
  };
  if (props.className) {
    p.className = classNames(element.props.className, props.className);
  }
  if (props.style) {
    p.style = {
      ...p.style,
      ...props.style,
    };
  }
  if (props.tabIndex !== undefined) {
    p.tabIndex = props.tabIndex;
  }
  for (const key in props) {
    if (!Object.prototype.hasOwnProperty.call(props, key)) continue;
    if (key.startsWith('data-') || key.startsWith('aria-')) {
      p[key] = props[key];
    }
  }
  return React.cloneElement(element, p);
}

const isBrowser = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);
export const useIsomorphicLayoutEffect = isBrowser ? useLayoutEffect : useEffect;

export function bound(position: number, min: number | undefined, max: number | undefined) {
  let ret = position;
  if (min !== undefined) {
    ret = Math.max(position, min);
  }
  if (max !== undefined) {
    ret = Math.min(ret, max);
  }
  return ret;
}

type Merge<T, P> = {
  [K in keyof T & keyof P]: P[K] | T[K];
};

export function mergeFuncProps<T extends Record<string, any>, P extends Record<string, any>>(
  p1: T,
  p2: P,
): Merge<T, P> {
  const p1Keys = Object.keys(p1);
  const p2Keys = Object.keys(p2);
  const keys = new Set([...p1Keys, ...p2Keys]);
  const res: any = {};

  keys.forEach((key) => {
    const p1Value = p1[key];
    const p2Value = p2[key];

    if (typeof p1Value === 'function' && typeof p2Value === 'function') {
      res[key] = function (...args: any[]) {
        p1Value(...args);
        p2Value(...args);
      };
    } else {
      res[key] = p1Value || p2Value;
    }
  });

  return res;
}

export function mergeProps<A, B>(a: A, b: B): B & A;
// eslint-disable-next-line no-redeclare
export function mergeProps<A, B, C>(a: A, b: B, c: C): C & B & A;
// eslint-disable-next-line no-redeclare
export function mergeProps(...items: any[]) {
  function customizer(objValue: any, srcValue: any) {
    return srcValue === undefined ? objValue : srcValue;
  }

  let ret = { ...items[0] };
  for (let i = 1; i < items.length; i++) {
    ret = assignWith(ret, items[i], customizer);
  }
  return ret;
}

export function devWarning(...args: any[]) {
  console.warn(...args);
}
