// 于RN共享的全局配置

import { call, callLinkReplace } from '@/bridge';
import { getLocalItem, setLocalItem } from '@/module/storage';
import {
  getUrlProductId,
  isH5Url,
  parseObjectToQueryUrl,
  parseQueryUrlToObject,
  parseUrlToObject,
  replaceParam,
} from '@/module/url';
import { ProductMainType } from '@/types/policy';
import { custom, customErr } from './exceptionHandler';
import { NO_LOGIN_WHITE_LIST } from './util';

const GLOBAL_CONFIG_KEY = '__INS__GLOBAL_CONFIG';
export interface GlobalConfig {
  switchToH5: '0' | '1';
  // product id跳转
  pdpLink: {
    desc: string;
    productId: string;
    url: string;
    types: string[];
  }[];
  // 类别跳转
  productMainTypeLink: {
    productMainType: ProductMainType;
    url: string;
  }[];
  switchToSPMSDK?: '0' | '1';
  // 级联选择器 搜索高亮
  csSearchHighlight?: '0' | '1';
}

const callForceReplaceLink = (target: string, config: string) => {
  let redirectUrl = target;
  const params: Record<string, string> = {};
  const search = config.split('?')[1]?.split('&');
  if (!search) return redirectUrl;
  search.forEach((item) => {
    const [key, value] = [...item.split('=')];
    params[key] = value || '';
  });
  Object.keys(params).forEach((param) => {
    redirectUrl = replaceParam(param, params[param], redirectUrl);
  });
  redirectUrl != target ? callLinkReplace(redirectUrl) : '';
};

const validatePdpLink = (list: { productId: string; url: string; types: 'force'[] }[]) => {
  const curProductId = getUrlProductId();
  if (!list || !curProductId) return;
  const { pathname, href } = window.location;
  // ec-pdp 可以不处理
  if (NO_LOGIN_WHITE_LIST.indexOf(pathname) >= 0) return;
  let isPdp = false;
  let matchLink: { url?: string; pathname?: string } = {};
  for (const config of list) {
    const configPathName = config.url.match(/^(?:https?:\/\/[^/]+)?(\/[^?#]+)/)?.[1];
    isPdp = isPdp || configPathName == pathname;
    if (config.productId == curProductId) {
      matchLink = { url: config.url, pathname: configPathName };
    }
    if (isPdp && pathname == matchLink.pathname) {
      if (!config.types?.includes('force')) return;
      callForceReplaceLink(href, config.url);
      return;
    }
    if (isPdp && matchLink.pathname && pathname != matchLink.pathname) {
      console.warn('match_pdp_link_error', {
        code: 1,
        matchLink,
      });
      const redirectUrl = href.replace(pathname, matchLink.pathname || '');
      if (!config.types?.includes('force')) {
        callLinkReplace(redirectUrl);
        return;
      }
      callForceReplaceLink(redirectUrl, config.url);
      return;
    }
  }
  if (!isPdp) return;
  console.warn('match_pdp_link_error', {
    code: 2,
    matchLink,
  });
};
const allMustDirect = (data?: any) => {
  if (!data || !data.redirectTo || data.redirectTo.length == 0) return;
  let href = window.location.href;
  return data.redirectTo.some((item: { from: string; to: string }) => {
    if (!item || !item.from || !item.to) return;
    const m = href.match(new RegExp(item.from.trim()));
    if (m) {
      let to = item.to.trim();
      const newHref = parseUrlToObject(to);
      // from: /ins/car-pdp.html\?productid=(1672933236801558528)
      // to : /ins/car-pdp.html?product_id=[1]
      // 把 Productid这个错误的字段，替换成正确的，[1]就是 from 匹配的第一个索引
      for (let i = 1; i < m.length; i++) {
        newHref.search = newHref.search.replace(`[${i}]`, m[i]);
        href = href.replace(new RegExp(`[^?&#]*=${m[i]}&?`), '');
      }

      const oldSeach = parseQueryUrlToObject(href);

      if (!isH5Url(to)) {
        to = `${location.protocol}//${location.host}${to}`;
      }

      const newSearch = parseQueryUrlToObject(newHref.search);
      const search = parseObjectToQueryUrl(Object.assign(oldSeach, newSearch));

      callLinkReplace(
        `${`${newHref.protocol}//${newHref.host}`}${newHref.pathname}${search ? `?${search}` : ''}${
          newHref.hash ? `#${newHref.hash}` : ''
        }`,
      );
      return true;
    }
  });
};
let globalConfigData: any;
let hooks: () => void = () => {};
const GET_GLOBAL_REPORT_KEY = 'UserGlobalConfig-load';
/**
 *
 * @param waitNew 是否等待当次最新的请求完成，用户在第一次访问页面的时候，如果没有等待请求结果，则可能没有数据
 * @returns
 */
export const getGlobalConfig = async (waitNew?: boolean): Promise<GlobalConfig> => {
  return new Promise((resolve) => {
    if (globalConfigData) return resolve(globalConfigData);
    const exe = () => {
      getLocalItem(GLOBAL_CONFIG_KEY).then((result: any) => {
        if (globalConfigData) return resolve(globalConfigData);
        if (result) {
          globalConfigData = result;
          return resolve(result);
        }
        call('load', {
          key: GLOBAL_CONFIG_KEY,
        })
          .then(async (val: { data: string; status: 0 | 1 }) => {
            if (globalConfigData) {
              return resolve(globalConfigData);
            }
            if (val && (+val?.status === 0 || !val.data)) {
              customErr(GET_GLOBAL_REPORT_KEY, { val: JSON.stringify(val), code: 1 });
              if (globalConfigData === 0) {
                return resolve({} as any);
              }
              hooks = () => {
                if (globalConfigData) {
                  return resolve(globalConfigData);
                }
                return resolve({} as any);
              };
              return;
            }
            custom(GET_GLOBAL_REPORT_KEY, { code: 0 });
            const d = JSON.parse(val.data);
            globalConfigData = d;
            return resolve(d);
          })
          .catch((error: any) => {
            if (globalConfigData) {
              return resolve(globalConfigData);
            }
            customErr(GET_GLOBAL_REPORT_KEY, { error, code: 2 });
            return resolve({} as any);
          });
      });
    };
    if (waitNew && globalConfigData !== 0) {
      hooks = () => {
        if (globalConfigData) {
          return resolve(globalConfigData);
        }
        exe();
      };
      return;
    }
    exe();
  });
};
export const fetchConfig = () => {
  PFC.getPFCAsync<Record<string, any>>('userGlobal')
    .then(async (json) => {
      json = json || {};
      const match = allMustDirect(json);
      globalConfigData = json;
      hooks();
      !match && validatePdpLink(json.pdpLink);
      setLocalItem(GLOBAL_CONFIG_KEY, json);
      return call('save', {
        key: GLOBAL_CONFIG_KEY,
        data: JSON.stringify(json),
        // 持久化存储
        persist: 1,
      }).then((val: { status: 0 | 1 }) => {
        if (val && +val.status === 0) {
          return custom('UserGlobalConfig-save');
        }
        return val;
      });
    })
    .catch(() => {
      globalConfigData = 0;
      hooks();
      getGlobalConfig().then(() => {
        globalConfigData && validatePdpLink(globalConfigData.pdpLink);
      });
    });
};
