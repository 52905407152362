import { isBroker } from '@/types/ph/kyc';

const { REACT_APP_ENV } = process.env;
const subConfig =
  REACT_APP_ENV === 'live'
    ? {
        appId: 'h5',
        secretKey: '672b90696137cb63d8ebd0a5913d070b',
        publicKey: '',
      }
    : {
        appId: 'h5',
        secretKey: 'trtlfq1b91gzbzkag0jx3wxbhzh3lhfr',
        publicKey: '',
      };

const envConfig = (() => {
  // mock, dev, test1
  if (['mock', 'dev', 'test1', 'test'].indexOf(REACT_APP_ENV) >= 0) {
    return {
      uploadHost: `https://document.protection.test.${
        isBroker ? 'seainsurebroker' : 'seainsureagency'
      }.com.ph`,
      livenessUplaodHost: 'https://document.protection.test.shopee.ph',
      helpCenterLink: 'https://help.test.shopee.ph/portal',
    };
  }

  // live
  if (REACT_APP_ENV === 'live') {
    return {
      uploadHost: `https://document.protection.${
        isBroker ? 'seainsurebroker' : 'seainsureagency'
      }.com.ph`,
      livenessUplaodHost: 'https://document.protection.shopee.ph',
      helpCenterLink: 'https://help.shopee.ph/portal',
    };
  }

  // test, uat
  return {
    uploadHost: `https://document.protection.uat.${
      isBroker ? 'seainsurebroker' : 'seainsureagency'
    }.com.ph`,
    livenessUplaodHost: 'https://document.protection.uat.shopee.ph',
    helpCenterLink: 'https://help.uat.shopee.ph/portal',
  };
})();

const config = {
  platForm: 1,
  version: 1,
  region: 'ph',
  ...subConfig,
  ...envConfig,
};

export default config;
