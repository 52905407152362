import { showLoading as showCommonLoading, hideLoading } from '@/components/BasicLoading';
import axios, { AxiosRequestConfig } from 'axios';
import config from '@/config';
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import hmacSHA256 from 'crypto-js/hmac-sha256';
// @ts-ignore
import Base64 from 'crypto-js/enc-base64';
import { detectStokenEnv, goLogin } from './login';
// import { getLocale } from '@/locales/runtime-init';
import { getUrlParamByKey } from '@/module/url';
import { formatMsg } from '@/locales';
import { APP_ENV, isAndroid, isIOS, isUndefined } from './util';
import { getAppInfo } from '@/bridge';

// https://github.com/axios/axios

// PH跨域调用上传接口，开启跨域cookie携带
axios.defaults.withCredentials = true;
// NOTE non-live暂时调高超时阈值
axios.defaults.timeout = 30000; // process.env.REACT_APP_ENV === 'live' ? 10000 : 20000;
const isAdmin = getUrlParamByKey('from') == 'admin';

// axios.defaults.baseURL = process.env.REACT_APP_ENV === 'mock' ? '/api/h5' : '';
export interface IRequestParam extends AxiosRequestConfig {
  login_type?: 1 | 2;
  login_value?: Record<string, any>;
  onLogin?: () => void;
  retryCount?: number;
  from?: string;
  showLoading?: boolean;
  // loading时是否禁止交互
  choke?: boolean;
  sucCode?: number | number[];
  // 不管成功还是失败都是回调 resolve([error,data]) 如果 error 是 null 说明是成功的，如果 error不是null ，则是错误信息
  errFirst?: boolean;
}
interface IObject {
  [propName: string]: any;
}
interface IRequestObject {
  [propName: string]: { resolve: Function; reject: Function; from?: string }[];
}
const requestObject: IRequestObject = {};
function _executeResolve(key: string, datas: IObject, result: boolean, options: IRequestParam) {
  const list = requestObject[key];
  if (!list || list.length == 0) return;
  const resultFun = result ? 'resolve' : 'reject';
  list.forEach((d) => {
    if (options.errFirst) {
      d.resolve([result ? null : datas, datas || {}]);
    } else {
      d[resultFun](datas || {});
    }
  });
  delete requestObject[key];
}
/**
 *
 * @param params 公共接口请求
 * 1、封装了多模块接口请求等待：
 *   如模块 A 和模块 B 都需要获取用户信息，且同时发起的，则比如A先请求，则B会等待A请求完成，将结果毁掉给A,B
 * 2、同一个from 来源，会做防重请求
 *
 * @example
 * request({
    method: 'get',
    url: '/user/12345',
    retryCount:1,// default 1 请求重试次数
    from:'detailGetUser', // 可选项 ：请求的来源，即哪个模块发起的这个请求，如果指定了 from，那么这里根据 from 做防重请求，即上一个 from 没有请求完成，这个 from 的请求不会发起
    data: {
      firstName: 'Fred',
      lastName: 'Flintstone'
    }
  }).then((response)=>{
  })
  .catch((err)=>{
  })
 */

export const getSignature = (obj: {
  url: string;
  method: string;
  params: any;
  timestamp: number;
  uuid: string;
  config: any;
}) => {
  const { secretKey } = config;
  const { url, method, timestamp, uuid, params } = obj;
  const str = [method ?? '', url ?? '', timestamp ?? '', uuid ?? '', JSON.stringify(params ?? '')]
    .map((it) => `${it}\n`)
    .join('');
  // console.log(str);
  return Base64.stringify(hmacSHA256(str, secretKey));
};
// ExShopeepayUserTokenInvalid             = exception.NewException(90032, "shopeepay user token invalid or expired")
// ExCallShopeepayVerifyProductTokenFailed = exception.NewException(90033, "call shopeepay VerifyProductToken api failed")
// ExShopeepayVerifyProductTokenRespError  = exception.NewException(90034, "shopeepay VerifyProductToken api resp error")
// ExCallShopeepayGetSpUidByUidFailed      = exception.NewException(90035, "call shopeepay GetSpUidByUid api failed")
// ExShopeepayGetSpUidByUidRespError       = exception.NewException(90036, "shopeepay GetSpUidByUid api resp error")
const NEED_LOGIN = [
  90006, 90008, 10114001, 10104001, 90011, 90012, 90032, 90033, 90034, 90035, 90036, 102011401,
];
// const COMMON_CODE = {
//   INVALIDE_COOKIE: '90006',
//   INVALID_ITOKEN_UPLOAD: '10114001',
//   INVALID_ITOKEN_OTHER: '10104001',
// };
const apiTimeout = (PFC.getPFC<Record<string, any>>('userGlobal')?.apiTimeout || []).reduce(
  (result: any, item: any) => {
    result[item.url] = +item.timeout;
    return result;
  },
  {},
);

function isTriggerLogin(api: string) {
  const apiList = PFC.getPFC<{ list: string[] }>('triggerLoginApi')?.list;
  return !apiList || apiList.includes(api);
}

export default function request(options: IRequestParam): Promise<any> {
  return new Promise(async (resolve, reject) => {
    const v = { resolve, reject };
    const requestKey = JSON.stringify({
      url: options.url,
      data: options.data,
      params: options.params,
    });
    if (requestObject[requestKey]) {
      // key 一致，说明是同一个来源的请求，实现去重复的功能
      if (!options.from || options.from != requestObject[requestKey][0].from) {
        requestObject[requestKey].push(v);
      }
      return;
    }
    // 记录下当前的请求，然后把key 标识也记下来，通常 key 是未 undefined，
    // 只有页面有多处地方调用同一个方法的时候，需要传参key，来标识不同的来源
    requestObject[requestKey] = [v];
    requestObject[requestKey][0].from = options.from;
    const retryCount = options.retryCount || 1;
    options.baseURL = options.baseURL || '';
    if (options.method) {
      options.method = options.method.toLowerCase();
    }
    const { appId, secretKey } = config;
    const uuid = uuidv4();
    const timestamp = Date.now();
    const basicHeader: Record<string, any> = {
      'X-Req-AppId': appId,
      'X-Req-AppEnv': APP_ENV,
      'X-Req-Nonce': uuid,
      'X-Req-TraceId': uuid,
      'X-Req-Timestamp': timestamp,
      'X-Req-Lang': window.INS_LOCALE.toUpperCase(),
      ...options.headers,
    };
    if (detectStokenEnv()) {
      // appInfo 有缓存
      const { appDeviceID } = (await getAppInfo()) || {};
      if (appDeviceID) {
        basicHeader['device-id'] = appDeviceID;
        // eslint-disable-next-line no-nested-ternary
        basicHeader['device-os'] = isIOS() ? 1 : isAndroid() ? 2 : 0;
      }
    }
    options.headers = basicHeader;
    if (isUndefined(options.timeout)) {
      options.timeout = apiTimeout[options.url!] || axios.defaults.timeout;
    }
    if (!options.data) options.data = {};
    if (!options.data.version) {
      const version = getUrlParamByKey('version');
      if (version) {
        options.data.version = version;
      }
    }
    if (options.showLoading) {
      showCommonLoading({
        choke: options.choke,
      });
    }
    // function report(
    //   code: number,
    //   status: boolean,
    //   startTime: number,
    //   request_id?: string,
    //   error?: any,
    //   http_status?: string | number,
    // ) {
    //   api(options.url!, {
    //     code,
    //     status,
    //     type: 'xhr',
    //     duration: Date.now() - startTime,
    //     req: options.data,
    //     request_id,
    //     http_status,
    //     error,
    //   });
    // }
    // custom(options.url!, {
    //   loc: 'api',
    //   start_time: Date.now(),
    //   req: options.data,
    // });
    function req(count: number, msg = {}) {
      // const startTime = Date.now();
      if (count > retryCount) {
        // !window.isJumpingLock &&
        //   // @ts-ignore
        //   report(-1, false, startTime, undefined, msg, (msg.response || {}).status);
        if (options.showLoading) {
          hideLoading();
        }
        return _executeResolve(
          requestKey,
          // @ts-ignore
          msg.message || formatMsg('Network error. Please try again later.'),
          false,
          options,
        );
      }
      options.headers!['X-Req-Signature'] = getSignature({
        url: `${options.url}`,
        // @ts-ignore
        method: options.method?.toUpperCase(),
        params: options.params || options.data || {},
        timestamp,
        uuid,
        config: { secretKey },
      });
      axios(options)
        .then((response) => {
          if (options.showLoading) {
            hideLoading();
          }
          const data = (response || {}).data || {};
          // TODO login & other common
          if (
            options.login_type !== 1 &&
            NEED_LOGIN.indexOf(+data.code) != -1 &&
            !isAdmin &&
            isTriggerLogin(options.url!)
          ) {
            delete requestObject[requestKey];
            options.onLogin?.();
            return goLogin(options.login_type === 2 ? options.login_value : undefined);
          }

          // let sucCode: number[] = [0];
          // if (Array.isArray(options.sucCode)) {
          //   sucCode = sucCode.concat(options.sucCode);
          // } else if (options.sucCode) {
          //   sucCode.push(+options.sucCode);
          // }
          // const isSuccess = sucCode.indexOf(+data.code) != -1;
          // report(
          //   data.code,
          //   isSuccess,
          //   startTime,
          //   data.request_id,
          //   undefined,
          //   (response || {}).status,
          // );

          _executeResolve(requestKey, data, true, options);
        })
        .catch((error) => {
          options.data.req_retry = ++count;
          req(count, error);
        });
    }
    req(0);
  });
}
