const TIME_DIFF = (function () {
  let time_diff = 0;
  if (process.env.REGION == 'id' || process.env.REGION == 'th' || process.env.REGION == 'vn') {
    time_diff = -420;
  } else if (process.env.REGION == 'br') {
    time_diff = +180;
  } else if (
    process.env.REGION == 'my' ||
    process.env.REGION == 'sg' ||
    process.env.REGION == 'ph'
  ) {
    time_diff = -480;
  } else {
    // 不用时区
    return time_diff;
  }

  time_diff = (new Date().getTimezoneOffset() - time_diff) * 60 * 1000;
  return time_diff;
})();

/**
 * 获取当前产品国家的当前时间，比如 ID ,VN, MY ,TH
 * @returns
 */
export function getNow(): number {
  return Date.now() + TIME_DIFF;
}
/**
 * 将服务器的时间戳，转换成服务器的时间格式
 * 比如 ID 服务下发的 timestamp  = 0 代表的是印尼的 1970-07-01 07:00:00
 * 那么在页面显示，应该也显示 1970-07-01 07:00:00
 * 假如当前是东八区，直接 new Date(0) 就会变成 08:00 了，因此需要转换。
 * @param timestamp 服务器的时间戳
 * @returns
 */
export function timeZone(timestamp: string | number): number {
  if (timestamp && +timestamp > 0) {
    return +timestamp + TIME_DIFF;
  }
  return +timestamp || 0;
}
