import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import './index.scss';

export type ICustomBtn = {
  type: 'normal' | 'primary';
  cb?: MouseEventHandler<HTMLDivElement>;
  text: string;
};

export type IPopupMessage = {
  content?: JSX.Element | string;
  title?: string;
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
  onContainerClick?: MouseEventHandler<HTMLDivElement>;
  onWrapperClick?: MouseEventHandler<HTMLDivElement>;
  okBtn?: boolean;
  cancelBtn?: boolean;
  // 默认horizontal水平分布、vertical垂直分布、vertical-reverse垂直反向分布
  btnLayout?: string;
  isRoundBtn?: boolean;
  btns?: ICustomBtn[];
};

export default function PopupMessageElement(props: IPopupMessage) {
  const {
    content = null,
    title,
    okText,
    cancelText,
    onOk,
    onCancel,
    onContainerClick,
    onWrapperClick,
    btnLayout = 'horizontal',
    isRoundBtn = false,
    btns,
  } = props;
  return (
    <div className="_popup_container" onClick={onContainerClick}>
      <div className="_popup_wrapper" onClick={onWrapperClick}>
        {title && <div className="_popup_title">{title}</div>}
        {typeof content === 'string' ? <div className="_popup_content">{content}</div> : content}
        <div
          className={classNames('_popup_btn_group', `_popup_btn_group--${btnLayout}`, {
            '_popup_btn_group--hideborder': isRoundBtn,
          })}
        >
          {btns && btns?.length > 0 ? (
            <>
              {btns.map((val, ind) => {
                return (
                  <div
                    key={ind}
                    className={classNames('_popup_btn', {
                      _popup_btn_cancel: val.type === 'normal',
                    })}
                    onClick={val.cb}
                  >
                    {val.text}
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {cancelText && (
                <div className="_popup_btn _popup_btn_cancel" onClick={onCancel}>
                  {cancelText}
                </div>
              )}
              {okText && (
                <div
                  className={classNames({
                    _popup_btn: !isRoundBtn,
                    _popup_btn_round: isRoundBtn,
                  })}
                  onClick={onOk}
                >
                  {okText}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
