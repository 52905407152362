// eslint-disable-next-line import/no-dynamic-require
// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
import I18n, { IOption } from './i18n';
import { isH5Url } from '@/module/url';

const i18n = new I18n();

export const formatMsg = (key: string, value?: Record<string, any>, option?: IOption) => {
  if (!key) {
    console.warn('formatMsg_key_empty', value, option);
    return '';
  }
  const translation = i18n.t(key, value, option);
  if (key.indexOf('img') === 0 && !isH5Url(translation)) {
    console.warn('formatMsg_img', `Img KEY: {${key}} is error.`);
  }

  return translation;
};
window.formatMsg = formatMsg;
export const formatValue = (key: string, value?: Record<string, any>) => {
  if (!key) {
    return '';
  }
  if (typeof key != 'string' || !value) return key;
  return key.replace(/{{?([^}]*)}?}/g, (a: string, b: string) => {
    return value[b.trim()] || b;
  });
};
window.formatValue = formatValue;

// const transifyMap = {
//   my: 'ms-my',
//   zh: 'zh-hans',
// };
// export function fetchLang() {
//   const localeLang = (transifyMap as any)[window.INS_LOCALE] || window.INS_LOCALE;
//   const { projectId } = window.regionSetting;
//   const url =
//     process.env.REACT_APP_ENV === 'live'
//       ? `https://deo.shopeemobile.com/shopee/shopee-seller-live-sg/tsp/api/r_${projectId}_${localeLang}.json`
//       : `https://seller.shopee.sg/api/tsp/nonlive/transify?resource_id=${projectId}&language=${localeLang}`;

//   return fetch(url).then(async (res) => {
//     if (res.ok) {
//       const result = await res.json();
//       // @ts-ignore
//       window.__PFCS__.savePFC('transify', result);
//     }
//   });
// }
