// import './runtime-init';
import { isPureObject } from '@/common/util';
import { getLastName } from '@/module/util';
import transifyUsageReport from './report';

export type IOption = {
  /**
   *  如果是空，则返回空，而不是返回 key
   */
  emptyStrict: boolean;
};
class I18n {
  transify?: Record<string, string>;
  getTransify() {
    if (!this.transify) {
      const transify = PFC.getPFC<Record<string, string>>('transify');
      const transifyPage = PFC.getPFC<Record<string, string>>(`transify_page_${location.pathname}`);
      if (transify || transifyPage) {
        this.transify = {
          ...transify,
          ...transifyPage,
        };
      }
    }
    return this.transify;
  }
  t(key: string, value?: Record<string, any>, option?: IOption) {
    if (!key) return '';
    const finalKey = key.trim();
    
    
    if (process.env.REACT_APP_ENV == 'test' || process.env.REACT_APP_ENV == 'uat') {
      transifyUsageReport && transifyUsageReport.addTransifyKey(finalKey);
    }

    const r = this.getTransify();
    if (!r) return finalKey;
    const result = r[key] || r[finalKey];
    if (!result && option && option.emptyStrict) return '';
    if (!result) {
      // 只有英语，可能没有国际化配置
      if (['sg', 'ph'].includes(process.env.REGION)) {
        return finalKey;
      }

      // 未配置
      if (typeof result == 'undefined') {
        // console.error('formatMsg_key', { key, value, option });
        const keysub = getLastName(finalKey);
        return process.env.REACT_APP_ENV === 'test' ? `[${keysub}]` : keysub;
      }
      // 如果配置空
      if (!result) {
        // console.warn('formatMsg_value_empty', { key, value, option });
        if (process.env.REACT_APP_ENV == 'live') {
          return '';
        }
        const keysub = getLastName(finalKey);
        if (process.env.REACT_APP_ENV == 'uat') {
          return `[${keysub}]`;
        }
        return keysub;
      }
    }

    if (!value || !isPureObject(value)) return result;
    // {{pay_amount}}
    return result.replace(/{{?([^}]*)}?}/g, (a: string, b: string) => {
      return value[b.trim()] || b;
    });
  }
}
export default I18n;
