import { call } from '@/bridge';
import { useEffect, useRef } from 'react';

export function useViewWillReappear(fn: (data: any) => void) {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  useEffect(() => {
    const viewWillReappear = call('viewWillReappear', function (data: any) {
      // 空数据兼容 防止回调函数报错
      fnRef.current(data || {});
    });
    return () => {
      try {
        viewWillReappear.then((res) => {
          res && res.unregister && res.unregister();
        });
      } catch (err) {
        console.error(err);
      }
    };
  }, []);
}
