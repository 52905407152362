// import Idap from '@finance/idap';
// // import { call } from '../bridge';
import { call } from '@/bridge';

// const idapSDK = new Idap({
//   bridgeCall: true,
//   business_id: '1003',
//   console: true,
//   debug: true,
//   api_response_code: {
//     key: 'code',
//     code: 0,
//   },
//   log_report_level: 'warn',
// });
// export default idapSDK;

// window.idapLayer = window.idapLayer || [];
// function custom(unique_key: string, params?: any) {
//   window.idapLayer.push({ report_type: 'custom', report_key: unique_key, ...params });
// }
let isUpdated = false;
window.idapLayer = window.idapLayer || [];
function updateBridge() {
  if (isUpdated) return;
  isUpdated = true;
  window.idapLayer.push({ report_type: 'updateBridge', bridge: call });
}
export default {
  custom(unique_key: string, params?: Record<string, any>) {
    updateBridge();
    window.idapLayer.push({ report_type: 'custom', report_key: unique_key, ...params });
  },
  trackConfig(page_type: string, autoImpress = false, data?: Record<string, any>) {
    updateBridge();
    window.idapLayer.push({ report_type: 'trackConfig', page_type, autoImpress, ...data });
  },
  track(key: string, data?: any) {
    updateBridge();
    window.idapLayer.push({ report_type: key, ...data });
  },
  speed(data: any, custom_origin?: any) {
    updateBridge();
    window.idapLayer.push({ report_type: 'speed', ...data, custom_origin });
  },
};
