import React, { useMemo, useState, useEffect, useRef } from 'react';
import Swiper from '@/components/SwiperV3';
import { EPageSource, EReferIdType, EResourceCode, ProductShelvesItem } from '@/types/common';
import './index.scss';
import { formatMsg } from '@/locales';
import classNames from 'classnames';
import useMemoizedFn from '@/hooks/useMemoizeFn';
import { queryRecommendInfo } from '@/common/service';
import ProductCard from '../ProductCard';
import { trackClick, trackReport } from '@/common/track';

export interface IRecommendCardProps {
  filtered_product?: string;
  skeleton?: boolean;
  card_title?: string;
  theme?: 'outer-title';
  card_layout?: 'rcmd-vertical' | 'rcmd-horizontal';
  fetchCardInfo?: any | 'pending';
  card_jump_handler?: (param: { product_id: string; product_main_type: string }) => void;
  onMoreClick?: () => void;
  trackConfig?: any;
  source?: EPageSource;
  resource_code?: EResourceCode;
  id_type?: EReferIdType;
  refer_id?: string;
}

function filterProduct(list: ProductShelvesItem[], filtered_product?: string) {
  if (filtered_product) {
    list = list.filter((item) => item.product_id != filtered_product);
  }
  return list;
}

const slideWidth = Math.ceil((288 / (351 - 24)) * 100);

function RecommendCard(props: IRecommendCardProps) {
  const {
    card_title = '',
    fetchCardInfo,
    theme,
    card_layout = 'rcmd-horizontal',
    filtered_product = '',
    trackConfig,
    onMoreClick,
    skeleton = true,
    resource_code,
    id_type,
    refer_id,
    card_jump_handler,
  } = props;
  // skeleton
  const [products, setProducts] = useState<ProductShelvesItem[]>(
    skeleton
      ? ([
          {
            product_display_name: '',
            premium_display: {
              show_premium_type: 'premium_message',
              premium_message: '',
            },
            key_selling_points: ['', '', ''],
          },
          {
            product_display_name: '',
            premium_display: {
              show_premium_type: 'premium_message',
              premium_message: '',
            },
            key_selling_points: ['', '', ''],
          },
        ] as ProductShelvesItem[])
      : [],
  );
  const requestDone = useRef(false);

  const handleCardClick = useMemoizedFn((item: ProductShelvesItem, index: number) => {
    trackClick({
      target_type: 'product_card',
      page_section: 'recommended_for_you',
      data: {
        location: index,
        product_id: item.product_id,
        promo_product_id: item.product_id,
        ...trackConfig?.data,
      },
    });
  });

  const getCardExpoProps = useMemoizedFn((item: ProductShelvesItem, index: number) => {
    if (!item?.product_id) return {} as any;
    return {
      'data-expo': item.product_id ? true : 'async',
      'data-params': JSON.stringify({
        target_type: 'product_card',
        page_section: 'recommended_for_you',
        data: {
          location: index,
          product_id: item.product_id,
          promo_product_id: item.product_id,
          ...trackConfig?.data,
        },
      }),
    };
  });

  const isVertical = card_layout === 'rcmd-vertical';
  const hasSetProduct = useRef(false);
  useEffect(() => {
    if (hasSetProduct.current) {
      hasSetProduct.current = false;
      trackReport();
    }
  });
  const fetchData = useMemoizedFn(() => {
    if (fetchCardInfo === 'pending') return;
    (fetchCardInfo
      ? fetchCardInfo()
      : queryRecommendInfo({
          resource_code: resource_code as EResourceCode,
          id_type,
          refer_id,
        })
    )
      .then((res: any) => {
        hasSetProduct.current = true;
        if (fetchCardInfo) {
          setProducts(res.data || []);
        } else {
          const { recommend_list } = res.data || {};
          setProducts(
            filterProduct(recommend_list?.[0]?.recommended_product_cards || [], filtered_product),
          );
        }
        requestDone.current = true;
      })
      .catch(() => {
        requestDone.current = true;
        setProducts([]);
      });
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCardInfo]);

  return useMemo(() => {
    return (
      <>
        {products.length ? (
          <div
            className={classNames('recommend', theme, {
              'recommend-vertical': isVertical,
              'recommend-horizontal': !isVertical,
            })}
          >
            {isVertical && onMoreClick && (
              <div className="recommend__more" onClick={onMoreClick}>
                {formatMsg('More')}
                <div className={classNames('recommend__more-arrow')}></div>
              </div>
            )}
            <div className="recommend_title">
              {requestDone.current ? card_title || formatMsg('common.recommendedcard.title') : ''}
            </div>
            {isVertical ? (
              <div className="vertical-recommend-list">
                {products.map((item, index) => (
                  <ProductCard
                    key={`product-card-${item?.product_id}-${index}`}
                    product={item as ProductShelvesItem}
                    card_layout={card_layout}
                    onClick={handleCardClick.bind(null, item, index)}
                    expoProps={getCardExpoProps(item, index)}
                    card_jump_handler={card_jump_handler}
                  />
                ))}
              </div>
            ) : (
              <div className="swiper-wrapper">
                <Swiper
                  slideSize={products.length > 1 ? slideWidth : undefined}
                  stopPropagation={['mousedown', 'mousemove']}
                >
                  {products.map((item, index) => (
                    <Swiper.Item key={`product-card-swiper-${item?.product_id}-${index}`}>
                      <ProductCard
                        key={`product-card-${item?.product_id}-${index}`}
                        product={item as ProductShelvesItem}
                        card_layout={card_layout}
                        onClick={handleCardClick.bind(null, item, index)}
                        expoProps={getCardExpoProps(item, index)}
                        card_jump_handler={card_jump_handler}
                      />
                    </Swiper.Item>
                  ))}
                </Swiper>
              </div>
            )}
          </div>
        ) : null}
      </>
    );
    // NOTE 请注意memo的更新依赖
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, card_title, theme, trackConfig, isVertical]);
}

export default RecommendCard;
