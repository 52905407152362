import { Swiper } from './swiper';
import { SwiperItem } from './swiper-item';
import { attachPropertiesToComponent } from './helper';
import './swiper.scss';

export type { SwiperProps, SwiperRef } from './swiper';
// https://mobile.ant.design/zh/components/swiper
export default attachPropertiesToComponent(Swiper, {
  Item: SwiperItem,
});
