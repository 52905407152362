// import { CustomPlugin } from '@mdap/javascript-sdk';
import idapSDK from './idap';

// JS
// const customReporter = new CustomPlugin({});
// const { REACT_APP_ENV } = process.env;
export enum EPointerKey {
  HTML_START = 'HTML_START',
  BEFORE_JS = 'BEFORE_JS',
  JS_START = 'JS_START',
  BEFORE_MAIN = 'BEFORE_MAIN',
  AFTER_MAIN = 'AFTER_MAIN',
  RENDER = 'RENDER',
  LANG = 'LANG',
}
// let pointerData = {
//   [EPointerKey.JS_START]: '11e0054b155f5153cf979eef0f527876',
//   [EPointerKey.BEFORE_MAIN]: 'ef511e5c402a83249cca13a84ca9864c',
//   [EPointerKey.AFTER_MAIN]: 'e3eb234ff4cc78d9ba0a2ecc07e4d2d4',
//   [EPointerKey.RENDER]: '63e791a7c4efff292de585da5639b2e4',
//   [EPointerKey.LANG]: 'c93ffbbedea2a94f59a7a4c4313a42e2',
// };
// if (REACT_APP_ENV === 'live') {
//   pointerData = {
//     [EPointerKey.JS_START]: '1509b45dc0fcedd7182c11ca6d818ebd',
//     [EPointerKey.BEFORE_MAIN]: '67ab357d851fcc262f955ddd5e35697c',
//     [EPointerKey.AFTER_MAIN]: 'd772b29b98dd51fa90fd890aad8ba0d0',
//     [EPointerKey.RENDER]: 'eb104b0d127588e715393d07b6c31e6b',
//     [EPointerKey.LANG]: '6ffa212f444ce70057bdc0f2c20b454a',
//   };
// }
// export const POINTER_DATA = pointerData;
export enum ProductType {
  MOTOR = 'motor',
  AH = 'ah',
}

export const reporter = function (pointKey: EPointerKey | ProductType, data?: Record<string, any>) {
  // if (REACT_APP_ENV === 'dev') {
  //   return;
  // }
  // customReporter.sendData({
  //   point_id: pointerData[pointKey],
  //   // @ts-ignore
  //   duration: Date.now() - window.htmlStartTime,
  //   data: {
  //     ...data,
  //     // custom data field - key-value
  //     apms_page_url: window.location.href,
  //     pathname: window.location.pathname,
  //     duration: window.performance ? performance.now() : Date.now(),
  //   },
  // });
  idapSDK.speed({ ...data, pointer: pointKey }, data && data.origin_time);
};

// export default customReporter;
