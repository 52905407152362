/**
 * 将已编码 URI 中所有能识别的转义序列转换成原字符\
 * 在 decodeURIComponent 的基础上兜底 unescape，解决不存在的编码，比如'%'
 * @param {string} value 编码后的部分 URI
 * @return {String} 一个解码后的统一资源标识符（URI）字符串，处理前的URI经过了给定格式的编码
 * @function safeDecodeURI
 */
export function safeDecodeURI(value?: string) {
  if (!value) return '';
  try {
    if (/(%[0-9A-F]{2}){2,}/.test(value)) {
      // utf8编码
      return decodeURIComponent(value);
    }
    // unicode编码
    return unescape(value);
  } catch (e) {
    return unescape(value);
  }
}
/**
 * 安全的 JSON.parse ，如果参数是对象等 parse 失败的则返回原值
 * @param value 字符串
 */
export function safeJSONParse(value: string | object) {
  if (!value) return {};
  if (typeof value == 'object') return value;
  try {
    return JSON.parse(value);
  } catch (error) {
    console.error('safeJSONParse', error);
    return {};
  }
}

/*
 * 处理多层级的字段
 * @param field policy_insured.certi_no  返回 certi_name
 */
export function getLastName(field: string) {
  if (!field) return '';
  const fieldNameArr = field.split('.');

  return fieldNameArr[fieldNameArr.length - 1];
}
