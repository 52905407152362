import { callWebviewUrl, navigateAppRL } from '@/bridge';
import { isH5Url, transformUrl } from '@/module/url';
import { EProductMainType } from '@/types/common';
import { ProductMainType } from '@/types/policy';
import { capture } from './exceptionHandler';
import { getGlobalConfig } from './globalConfig';
// import { ICurrentQuery } from './type';
import { isAndroid, isShopeePayApp } from './util';

interface IPdpSearch {
  tab?: string;
  scrollTo?: string; // RN pdp参数
  policy_id?: string;
  entry?: string;
}

// PDP跳转链接支持配完整的url或者path
// export const parsePathOrUrl = (
//   url: string,
// ): {
//   pathname: string;
//   host: string;
//   search: ICurrentQuery;
// } => {
//   // path  如 /ins
//   if (url.indexOf('/') === 0) {
//     const [pathname, searchUrl] = url.split('?');
//     return {
//       host: '',
//       pathname,
//       search: searchUrl ? parseQueryUrlToObject(searchUrl) : {},
//     };
//   }
//   // 带有host的链接
//   const ind = url.indexOf('/');
//   const host = url.slice(0, ind);
//   const [pathname, searchUrl] = url.slice(ind).split('?');
//   return {
//     host,
//     pathname,
//     search: searchUrl ? parseQueryUrlToObject(searchUrl) : {},
//   };
// };

const matchTwice = (url: string, prefix: string) => {
  const arr = url.split(prefix);
  return arr.length === 3;
};

// 兜底逻辑
const productMainTypeNavigate = (params: {
  product_id: string;
  product_main_type: ProductMainType;
  search?: IPdpSearch;
}): string => {
  const { product_id, product_main_type, search } = params;
  let url = '';
  if (!product_id) return url;
  switch (+EProductMainType[product_main_type]) {
    case 1: {
      // item production跳转
      url = transformUrl({
        pathname: `/product-protection/profile`,
        search: {
          product_id,
          app: 'insurance',
          hide_loading: 'true',
          ...search,
        },
      });
      break;
    }
    case 7: {
      // motor
      url = transformUrl({ pathname: '/ins/car-pdp.html', search: { product_id, ...search } });
      break;
    }
    default:
      // standalone 意健险产品
      url = transformUrl({
        pathname: '/ins/factory-pdp5.html',
        search: { product_id, ...search },
      });
      break;
  }
  return url;
};

export const getPdpUrl = async (params: {
  product_id: string;
  product_main_type: ProductMainType;
  search?: IPdpSearch;
}) => {
  let link = '';
  const { product_id, product_main_type, search } = params;
  let globalConfig;
  // 每次都从本地拉取最新配置
  try {
    globalConfig = await getGlobalConfig();
  } catch (e) {
    globalConfig = {};
  }

  const productCfg = globalConfig?.pdpLink?.find?.((val) => val.productId === product_id);
  const productMainTypeCfg = globalConfig?.productMainTypeLink?.find?.(
    (val) => val.productMainType === product_main_type,
  );
  if (productCfg && productCfg.url) {
    // 获取链接上的参数
    // const { host, pathname, search: linkSearch } = parsePathOrUrl(productCfg?.url);
    // 有product id跳转链接
    link = transformUrl(
      {
        search: {
          ...search,
          ...(productCfg.types?.includes('force') ? {} : { product_id }),
        },
      },
      productCfg.url,
    );
  } else if (productMainTypeCfg && productMainTypeCfg.url) {
    // const { host, pathname, search: linkSearch } = parsePathOrUrl(productMainTypeCfg?.url);
    // 有类别的模版链接
    // 由于是模版需要补上 product_id
    link = transformUrl(
      {
        search: {
          ...search,
          product_id,
        },
      },
      productMainTypeCfg.url,
    );
  } else {
    link = productMainTypeNavigate({
      product_id,
      product_main_type,
      search,
    });
  }
  // 防止admin上多配置了http/https
  if (matchTwice(link, 'http')) {
    const str = '://';
    const index = link.indexOf(str);
    link = link.slice(index + str.length);
  }
  return link;
};

export const handleNavigateToPdp = async (params: {
  product_id: string;
  product_main_type: ProductMainType;
  search?: IPdpSearch;
}) => {
  const link = await getPdpUrl(params);

  if (link.includes('/@shopee-rn')) {
    // RN apprl
    navigateAppRL(link);
  } else if (isH5Url(link)) {
    // webview
    callWebviewUrl(link, {
      navbar: {
        isTransparent: 1,
        navbarStyle: 1,
      },
    });
  } else {
    capture({
      name: `handleNavigateToPdpError`,
      message: `link: ${link}`,
    });
  }
};

export const getPopPageCount = (page: number, appVersion: number) => {
  // +page+1:加上payment-result
  // eslint-disable-next-line no-nested-ternary
  const _page = isAndroid() && +appVersion < 29100 && !isShopeePayApp ? 1 : page ? +page + 1 : 2;
  console.warn('getPopPageCount', { page, appVersion, _page });
  return _page;
};
