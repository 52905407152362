/**
 * 本地缓存 公共组件
  getItem,
  getLocalItem,
  getSessionItem,
  setItem,
  setLocalItem,
  setSessionItem,
  removeItem,
  clearOut,
 */
import { isPlainObject, mergeWith } from 'lodash-es';

const PREFIX = '_INS_';
enum IStorageType {
  session = 'session',
  local = 'local',
}
type TType = 'session' | 'local';
/**
 * 根据options获取缓存对象和key前缀
 * @param {object} [options={}]
 */
function getStorage(type?: TType) {
  return type == IStorageType.session ? window.sessionStorage : window.localStorage;
}
/**
 * 转换为JSON对象
 * @param {*} val
 * @returns {*}
 */
function parseJSON(val: string): any {
  if (!val || typeof val != 'string') {
    return val;
  }
  val = val.replace(/^\s+|\s+$/g, '');
  if (!val) return val;

  try {
    val = JSON.parse(val);
  } catch (e) {
    console.error('parseJSON', e);
  }
  return val;
}
interface ICache {
  exp: string;
  v: any;
}
/**
 * 判断缓存是否过期
 * @param {*} vObj 缓存对象
 * @returns {boolean} 是否过期
 */
function checkExpire(vObj: ICache) {
  if (vObj.exp && +vObj.exp < Date.now()) {
    return true;
  }
  return false;
}

/**
 * 判断是不是日期对象
 * @param  {any}  obj,要判断的参数
 * @return {Boolean}     是日期对象，则返回true，否则返回fale
 */
function _isDatetime(obj: any): boolean {
  return typeof obj == 'object' && obj.constructor.name == 'Date';
}
/**
 * 将参数中的时间转化为unix时间戳(即1970/1/1 00:00:00到现在的毫秒数）
 * @param {number/string/datetime} val 待转换的时间，有4种格式：
 *  1）参数为`yyyy/MM/dd h:m:s`格式的过时间字符串，绝对时间，兼容(yyyy-MM-dd h:m:s和yyyy.MM.dd h:m:s)；
 *  2）参数为日期对象，绝对时间；
 *  3）参数为数字，绝对时间，unix时间戳；
 *  4）格式为`\d+[smhd]`，其中s 表示秒、m 分钟、h 小时、d 天，如 30d，表示相当于当前时间的偏移，返回为当前时间+偏移的时间戳
 * @returns {number} unix时间戳,参数不正确时，返回-1
 * @function convertEndTime
 * @example
 * convertEndTime("30d")  // 即从当前时间 +  30天的时间会截止时间
 */
function convertEndTime(val: number | Date | string = 0): number {
  // 1.如果是数字，绝对时间，unix时间戳
  if (typeof val === 'number') {
    return val;
  }
  // 2.如果是日期对象，表示该日期的Unix时间戳
  if (_isDatetime(val)) {
    return (val as Date).getTime();
  }
  if (typeof val == 'string') {
    const matches = val.match(/^(\d+(?:\.\d+)?)([smhd])$/);
    // 3.如果是相对时间字符串 eg：1d，2h, 3m, 4s
    if (matches) {
      let ms = 0;
      const n = +matches[1];
      switch (matches[2]) {
        case 'm':
          ms = n * 60 * 1000;
          break;
        case 'h':
          ms = n * 60 * 60 * 1000;
          break;
        case 'd':
          ms = n * 24 * 60 * 60 * 1000;
          break;
        case 's':
        default:
          ms = n * 1000;
      }
      return Date.now() + Math.round(ms);
    }
    let d = new Date(val).getTime();
    if (!isNaN(d)) {
      return d;
    }
    // 4.如果传入的是绝对时间字符串，eg：2018/12/25 09:30:45  2018-12-25 09:30:45 2018.12.25 09:30:45
    d = new Date(val.replace(/[.-]/g, '/')).getTime();
    if (!isNaN(d)) {
      return d;
    }
  }
  return -1;
}

/**
 * 清除缓存
 * @param {string} key 缓存key
 * @param {string} type 类型 local | session
 * @returns void
 */
function removeItem(key: string, type: TType): Promise<boolean> {
  return new Promise(function (resolve) {
    if (!key) {
      console.error('key is null');
      return resolve(false);
    }
    const storage = getStorage(type);
    try {
      console.warn('removeItem', { key, type, error: new Error('remove') });
      storage.removeItem(PREFIX + key);
      resolve(true);
    } catch (e) {
      console.error('remove error');
      return resolve(false);
    }
  });
}
/**
 *
 * 获取缓存
 * @export
 * @param {string} key  存储key
 * @param {string} type , "local" || "session"
 * @param {*} [defaultVal] 默认值
 * @returns {Promise}
 */
function getItem(key: string, type: TType, defaultVal = null): Promise<any> {
  return new Promise(function (resolve) {
    if (!key && !defaultVal) {
      return resolve(defaultVal || null);
    }
    const storage = getStorage(type);

    // 先查找有前缀的
    const vObj = storage.getItem(PREFIX + key);

    if (!vObj) return resolve(defaultVal || null);

    const cachObj: ICache = parseJSON(vObj);

    // 检查是否过期
    if (checkExpire(cachObj)) {
      removeItem(key, type);
      return resolve(defaultVal);
    }
    resolve(cachObj.v);
  });
}

/**
 * 清空本地过期缓存
 * @param {string} type 清楚类型
 */
function clearOut(type: TType) {
  // 根据option参数获取storage对象和key前缀
  //  const { jdStorage, keyPrefix } = getFormatedParam(options);
  const storage = getStorage(type);
  let key = '';
  for (let i = storage.length - 1; i >= 0; i--) {
    key = storage.key(i) || '';
    key.indexOf(PREFIX) == 0 && getItem(key.slice(PREFIX.length), type);
  }
}
interface ICacheOpt {
  expire?: string | number;
  merge?: boolean;
  customizer?: Function;
}
function _objectLength(value: any = '') {
  try {
    return JSON.stringify(value).length;
  } catch (error) {
    return 0;
  }
}
/** *
 * 设置缓存
 * @param {string} key 缓存key
 * @param {*} value 缓存值
 * @param {string} type 默认是 ：local 为 localStorage， session 为 sessionStorage
 * @param {object} [options={}] 可选对象，如options.expire 过期时间，默认7天, options.merge 是否和已有的数据合并
 * @returns {Promise}
 */
function setItem(key: string, value: any, type: TType, options: ICacheOpt = {}): Promise<boolean> {
  return new Promise(async function (resolve) {
    if (!key || typeof key != 'string') {
      return resolve(false);
    }
    if (options.merge) {
      // 如果满足合并缓存对象的条件
      const v = await getItem(key, type);
      const before = _objectLength(v);

      console.warn('set_item_getitem_m', { key, type, b: before, c: value });
      if (
        (isPlainObject(value) && isPlainObject(v)) ||
        (Array.isArray(value) && Array.isArray(v))
      ) {
        value = mergeWith(v, value, options.customizer);
        console.warn('set_item_merge', {
          a: _objectLength(value),
        });
      }
    } else {
      console.warn('set_item_getitem', { key, type, c: value });
    }
    const defaultExp = '7d';
    if (typeof options.expire === 'undefined') {
      // 默认有效期一个星期
      options.expire = defaultExp;
    }
    const exp = convertEndTime(
      typeof options.expire == 'number' ? `${options.expire}s` : options.expire,
    );
    const newValueObj = {
      v: value,
      tag: process.env.RELEASE_TAG,
      exp: exp == -1 ? convertEndTime(defaultExp) : exp,
    };

    let newValueStr = '';

    try {
      newValueStr = JSON.stringify(newValueObj);
    } catch (e: any) {
      console.error('setItemJsonError', new Error(`JSON数据格式异常：${e && e.message}`));
      return resolve(false);
    }
    const storage = getStorage(type);
    key = PREFIX + key;
    try {
      storage.setItem(key, newValueStr);

      resolve(true);
    } catch (e) {
      console.error('setItemErrorFirst', key, e);
      setTimeout(function () {
        // 清除过期数据，重试一次
        clearOut(type);
        try {
          storage.setItem(key, newValueStr);
          resolve(true);
        } catch (e2) {
          console.error('setItemErrorSecond', key, e2);
          resolve(false);
        }
      }, 0);
    }
  });
}
/**
 *
 * 获取 localStorage 缓存
 * @export
 * @param {string} key  存储key
 * @param {*} [defaultVal] 默认值，即如果没有获取到值是否返回该默认值
 * @returns {Promise}
 */
function getLocalItem(key: string, defaultVal: any = null) {
  return getItem(key, IStorageType.local, defaultVal);
}
/**
 *
 * 获取 sessionStorage 缓存
 * @export
 * @param {string} key  存储key
 * @param {*} [defaultVal] 默认值，即如果没有获取到值是否返回该默认值
 * @returns {Promise}
 */
function getSessionItem(key: string, defaultVal: any = null) {
  return getItem(key, IStorageType.session, defaultVal);
}
/**
 * 清除缓存
 * @param {string} key 缓存key
 * @param {string} type 类型 local | session
 * @returns void
 */
function deleteByKey(
  key: string,
  deleteKey?: string | string[],
  type: TType = IStorageType.local,
): Promise<boolean> {
  return new Promise(function (resolve) {
    if (!key || !deleteKey) {
      return resolve(false);
    }
    try {
      getItem(key, type).then((data) => {
        if (!data) {
          return resolve(true);
        }
        let deleteKeyArr: string[];
        if (typeof deleteKey == 'string') {
          deleteKeyArr = [deleteKey];
        } else {
          deleteKeyArr = deleteKey;
        }
        deleteKeyArr.forEach((dKeys) => {
          const delArr = dKeys.split('.');

          let temp = data;
          let i = 1;
          for (; i < delArr.length && temp; i++) {
            temp = temp[delArr[i - 1]];
          }
          temp && delete temp[delArr[i - 1]];
        });
        if (!data || JSON.stringify(data) == '{}' || (Array.isArray(data) && data.length == 0)) {
          removeItem(key, type).then(resolve);
        } else {
          setItem(key, data, type, { merge: false }).then(resolve);
        }
      });
    } catch (e) {
      resolve(false);
    }
  });
}
/** *
 * 设置 localStorage 缓存
 * @param {string} key 缓存key
 * @param {*} value 缓存值
 * @param {object} [options={}] 可选对象，如options.expire 过期时间，默认7天, options.merge 是否和已有的数据合并
 * @param {number/string/datetime} options.expire 待转换的时间，有4种格式：
 *  1）参数为`yyyy/MM/dd h:m:s`格式的过时间字符串，绝对时间，兼容(yyyy-MM-dd h:m:s和yyyy.MM.dd h:m:s)；
 *  2）参数为日期对象，绝对时间；
 *  3）参数为数字（单位是s），绝对时间；
 *  4）格式为`\d+[smhd]`，其中s 表示秒、m 分钟、h 小时、d 天，如 30d，表示相当于当前时间的偏移，返回为当前时间+偏移的时间戳
 * @returns {Promise}
 * @example
 * setLocalItem("visitLastTime",1,{expire:'30d'});// 存储 visitLastTime 值为1 ，缓存 30天 有效
 * setLocalItem("visitLastTime",1,{expire:'24h'});// 存储 visitLastTime 值为1 ，缓存 24小时 有效
 * setLocalItem("visitLastTime",1,{expire:1000});// 存储 visitLastTime 值为1 ，缓存 1000s 有效
 * setLocalItem("userInfo",{name:'lwl'});// 存储 userInfo 信息，{name:'lwl'}
 * setLocalItem("userInfo",{age:18},{merge:true});// 存储 userInfo 信息，且合并数据，则最终存储的信息是：{name:'lwl'，age:18}
 */
function setLocalItem(key: string, value: any, options?: ICacheOpt) {
  return setItem(key, value, IStorageType.local, options);
}
/** *
 * 设置 sessionStorage 缓存
 * @param {string} key 缓存key
 * @param {*} value 缓存值f
 * @param {object} [options={}] 可选对象，如options.expire 过期时间，默认7天, options.merge 是否和已有的数据合并
 * @param {number/string/datetime} options.expire 待转换的时间，有4种格式：
 *  1）参数为`yyyy/MM/dd h:m:s`格式的过时间字符串，绝对时间，兼容(yyyy-MM-dd h:m:s和yyyy.MM.dd h:m:s)；
 *  2）参数为日期对象，绝对时间；
 *  3）参数为数字，绝对时间，unix时间戳；
 *  4）格式为`\d+[smhd]`，其中s 表示秒、m 分钟、h 小时、d 天，如 30d，表示相当于当前时间的偏移，返回为当前时间+偏移的时间戳
 * @example
 * setLocalItem("visitLastTime",1,{expire:'30d'});// 存储 visitLastTime 值为1 ，缓存 30天 有效
 * setLocalItem("visitLastTime",1,{expire:'24h'});// 存储 visitLastTime 值为1 ，缓存 24小时 有效
 * setLocalItem("visitLastTime",1,{expire:1000});// 存储 visitLastTime 值为1 ，缓存 1000s 有效
 * setLocalItem("userInfo",{name:'lwl'});// 存储 userInfo 信息，{name:'lwl'}
 * setLocalItem("userInfo",{age:18},{merge:true});// 存储 userInfo 信息，且合并数据，则最终存储的信息是：{name:'lwl'，age:18}
 */
function setSessionItem(key: string, value: any, options?: ICacheOpt) {
  return setItem(key, value, IStorageType.session, options);
}

export {
  getItem,
  getLocalItem,
  getSessionItem,
  setItem,
  setLocalItem,
  setSessionItem,
  removeItem,
  clearOut,
  deleteByKey,
};
